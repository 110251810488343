import React, { useEffect, useState } from 'react';

import {
  ChakraProvider,
  Box,
  Badge,
  Divider,
  Text,
  Link,
  VStack,
  Grid,
  Center,
  Stat,
  StatLabel,
  StatNumber,
  Stack,
  Table,
  Thead,
  Tbody,
  Image,
  Tr,
  Th,
  Td,
  TableContainer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Show,
  Hide,
  CircularProgress,
} from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import axios from './axions';
import GitInfo from 'react-git-info/macro';

const gitInfo = GitInfo();

const theme = extendTheme({
  colors: {
    blue: {
      600: 'rgb(45,105,49)',
    },
  },
});

const today = new Date();

function App() {
  const [Guthaben, setGuthaben] = useState(true);
  const [Transaktionen, setTransaktionen] = useState(true);
  const [Kartennummer, setKartennummer] = useState(true);
  const [Detailstransaktionen, setDetailstransaktionen] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    //for (const [key, value] of queryParams) {
    //  console.log({ key, value });
    //}

    setKartennummer(queryParams.get('id'));

    const getGuthaben = async () => {
      const response = await axios({
        method: 'get',
        url: queryParams.get('id'),
      });
      //console.log(response);

      if (!response.data.error) {
        setGuthaben(
          new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
          }).format(response.data.balance / 100)
        );
        setTransaktionen(response.data.transactions);
        setDetailstransaktionen(response.data.details);

        setLoading(false); //stop loading when data is fetched
      } else {
        alert(
          'Gutscheinnummer ist nicht vorhanden! Bitte prüfen Sie die Eingabe.'
        );
      }
    };

    if (queryParams.get('sc')) {
      getGuthaben();
    } else {
      alert(
        'Gutscheinnummer ist nicht vorhanden oder falsch! Bitte prüfen Sie die Eingabe.'
      );
    }
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Box p={6} display={{ sm: 'flex' }}>
        <Box flexShrink={0}>
          <Center>
            <Image
              width={{ base: 80, md: 60 }}
              src="https://www.ba-gu.de/bilder/Baho_Wortmarke.svg"
              alt="Bahnhof-Apotheke"
            />
          </Center>
        </Box>
        <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }} w="100%">
          <Text
            mt={2}
            fontSize={{ base: 24, sm: 29 }}
            textAlign={['center', 'right']}
          >
            Ihre Guthabenübersicht
          </Text>
        </Box>
      </Box>
      <Text fontSize="1em" textAlign={'center'} p={5}>
        Kartennummer:{' '}
        <Badge
          bg="rgb(240,234,227)"
          color="rgb(20,20,20)"
          p={3}
          borderRadius={15}
        >
          {Kartennummer}
        </Badge>
      </Text>
      <Box textAlign="center" fontSize="xl" bg="rgb(248,245,241)">
        <Grid minH="100vh" p={3}>
          <Box p="4">
            <Tabs>
              <Center>
                <TabList maxW="1000px">
                  <Tab mr={5}>Allgemein</Tab>
                  <Tab ml={5}>Details</Tab>
                </TabList>
              </Center>
              <TabPanels>
                <TabPanel>
                  <Box p={6} pb={0} display={{ sm: 'flex' }}>
                    <Box flexShrink={0} w={{ base: '100%', sm: '50%' }}>
                      <Stat>
                        <StatLabel>Aktuelles Guthaben</StatLabel>
                        <StatNumber fontSize="3em" color="rgb(45,105,49)">
                          {Guthaben}
                          {isLoading ? (
                            <CircularProgress
                              isIndeterminate
                              color="rgb(45,105,49)"
                            />
                          ) : (
                            ''
                          )}
                        </StatNumber>
                      </Stat>
                    </Box>
                    <Box ml={{ md: 6 }} w={{ base: '100%', md: '50%' }}>
                      <Hide above="sm">
                        <Divider m={5} />
                      </Hide>
                      <Show above="sm">
                        <Stack direction="row" h="100px">
                          <Divider orientation="vertical" />
                          <Stat m={0}>
                            <StatLabel m={0}>Transaktionen</StatLabel>
                            <StatNumber fontSize="3em" color="rgb(45,105,49)">
                              {Transaktionen}
                              {isLoading ? (
                                <CircularProgress
                                  isIndeterminate
                                  color="rgb(45,105,49)"
                                />
                              ) : (
                                ''
                              )}
                            </StatNumber>
                          </Stat>
                        </Stack>
                      </Show>
                      <Hide above="sm">
                        <Stat>
                          <StatLabel>Transaktionen</StatLabel>
                          <StatNumber fontSize="3em" color="rgb(45,105,49)">
                            {Transaktionen}
                            {isLoading ? (
                              <CircularProgress
                                isIndeterminate
                                color="rgb(45,105,49)"
                              />
                            ) : (
                              ''
                            )}
                          </StatNumber>
                        </Stat>
                      </Hide>
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel p={0} pt={2} m={0}>
                  <TableContainer>
                    <Center>
                      {isLoading ? (
                        <CircularProgress
                          isIndeterminate
                          color="rgb(45,105,49)"
                        />
                      ) : (
                        <Table size="sm" variant="simple" maxW="1000px">
                          <Thead>
                            <Tr>
                              <Th>Datum</Th>
                              <Th>Was</Th>
                              <Th isNumeric>Betrag</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {Detailstransaktionen?.map(key => (
                              <Tr key={key.id}>
                                <Td>
                                  {key.datum}
                                  <Show above="sm"> {key.zeit}</Show>
                                </Td>
                                <Td>{key.bemerkung}</Td>
                                <Td isNumeric>{key.betrag}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      )}
                    </Center>
                  </TableContainer>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
          <Center>
            <Box boxShadow="base" p="6" bg="white" maxW="1000px">
              <VStack spacing={8}>
                <Text>
                  Bewahren Sie diese Karte wie Bargeld auf. Das Guthaben wird
                  nicht verzinst. Eine Barauszahlung ist nicht möglich. Bei
                  Verlust, Entwendung oder Unlesbarkeit der Karte übernimmt der
                  Kartenherausgeber keine Haftung. Die Karte ist anonym und
                  übertragbar. Es gelten die jeweils aktuellen allgemeinen
                  Geschäftsbedingungen. <b>Online nicht einlösbar.</b>
                </Text>
                <Text>
                  {' '}
                  Sie können Ihren Gutschein auch in Teilbeträgen einlösen bei:
                </Text>
                <Image
                  width={{ base: 80, md: 60 }}
                  src="https://www.ba-gu.de/bilder/Baho_Wortmarke.svg"
                  alt="Bahnhof-Apotheke - Kempten im Allgäu"
                />
                <Image
                  width={{ base: 80, md: 60 }}
                  src="https://www.ba-gu.de/bilder/Baho_Klinikum_Wortmarke.svg"
                  alt="Bahnhof-Apotheke am Klinikum"
                />
                <Image
                  width={{ base: 60, md: 40 }}
                  src="https://www.ba-gu.de/bilder/PurNatur_Wortmarke_Kompakt.svg"
                  alt="PurNatur Kempten"
                />
                <Image
                  width={{ base: 60, md: 40 }}
                  src="https://www.ba-gu.de/bilder/PurNatur_Blumen_Wortmarke.svg"
                  alt="PurNatur Blumen Kempten"
                />
              </VStack>
            </Box>
          </Center>
        </Grid>
      </Box>
      <Box bg="rgb(45,105,49)" p="0" color="white">
        <Box p={4} display={{ sm: 'flex' }}>
          <Hide above="sm">
            <Center>
              <Box flexShrink={0}>
                <Text>© Bahnhof-Apotheke {today.getFullYear()}</Text>
              </Box>
            </Center>
          </Hide>
          <Show above="sm">
            <Box flexShrink={0}>
              <Text>
                © Bahnhof-Apotheke {today.getFullYear()}{' '}
                <small>({gitInfo.commit.date.substring(0, 16)})</small>
              </Text>
            </Box>
          </Show>

          <Box ml={{ md: 6 }} textAlign={'right'} width={'100%'}>
            <Hide above="sm">
              <Divider mt={3} mb={3} />
            </Hide>
            <Link
              color="white"
              href="https://bahnhof-apotheke.de/datenschutz"
              fontSize="sm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutz
            </Link>
            {' | '}
            <Link
              color="white"
              href="https://bahnhof-apotheke.de/impressum"
              fontSize="sm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Impressum
            </Link>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;
